import { useRouteLoaderData } from '@remix-run/react';
import {
  type ImageCdn,
  type UrlTransformer,
  type UrlTransformerOptions,
  getCanonicalCdnForUrl,
  getTransformer,
  transformUrl,
} from 'unpic';
import type { loader } from '~/root';

interface Props extends Omit<React.ComponentProps<'img'>, 'src'> {
  src: string;
  quality?: number;
}

export function Image({ quality = 75, ...props }: Props) {
  const data = useRouteLoaderData<typeof loader>('root');

  const isLocal = data?.ENV.NODE_ENV === 'development';
  const isCfImage =
    props.src.includes('assets.colette.club') || props.src.includes('assets.clubnester.com');

  const width = typeof props.width === 'string' ? 3840 : props.width || 3840;

  let aspectRatio: NonNullable<React.ComponentProps<'img'>['style']>['aspectRatio'] = undefined;
  if (
    props.width &&
    props.height &&
    typeof props.width === 'number' &&
    typeof props.height === 'number'
  ) {
    aspectRatio = `${props.width / props.height} / 1`;
  }

  const sizes =
    props.width && typeof props.width === 'number'
      ? `(min-width: ${props.width}px) ${props.width}px, 100vw`
      : '100vw';

  // biome-ignore lint/a11y/useAltText: This is a generic component
  return (
    <img
      loading="lazy"
      decoding="async"
      sizes={sizes}
      {...props}
      style={{ ...props.style, aspectRatio }}
      src={
        isCfImage
          ? transformUrl({
              url: `https://assets.colette.club/cdn-cgi/image/width=${width}&quality=${quality}/${props.src}`,
              cdn: 'cloudflare_images',
            })?.toString()
          : props.src
      }
      srcSet={
        isCfImage
          ? getSrcSet({
              src: `https://assets.colette.club/cdn-cgi/image/width=${width}&quality=${quality}/${props.src}`,
              cdn: 'cloudflare_images',
            })
          : isLocal
            ? undefined
            : getSrcSet({
                src: props.src,
                transformer: getTransformer('vercel'),
                width: typeof props.width === 'string' ? undefined : props.width,
                height: typeof props.height === 'string' ? undefined : props.height,
                quality,
              })
      }
    />
  );
}

export const getSrcSet = (options: SrcSetOptions & { quality?: number }): string => {
  let { src, cdn, transformer } = options;
  const canonical = getCanonicalCdnForUrl(src, cdn);

  if (canonical && !transformer) {
    transformer = getTransformer(canonical.cdn);
  }
  if (!transformer) {
    return '';
  }

  return getSrcSetEntries({ ...options, transformer })
    .map((transform) => {
      // biome-ignore lint/style/noNonNullAssertion: this is safe
      let url = transformer!(transform);

      if (typeof url === 'string') {
        url = url.replace('q=75', `q=${options.quality}`);
      }

      return `${url?.toString()} ${transform.width}w`;
    })
    .join(',\n');
};

export const getSrcSetEntries = ({
  src,
  height,
  aspectRatio,
  cdn,
  transformer,
  format,
  breakpoints,
  width,
}: SrcSetOptions): Array<UrlTransformerOptions> => {
  const canonical = getCanonicalCdnForUrl(src, cdn);

  if (canonical && !transformer) {
    transformer = getTransformer(canonical.cdn);
  }

  if (!transformer) {
    return [];
  }

  breakpoints ||= getBreakpoints({ width, cdn });

  return breakpoints
    .sort((a, b) => a - b)
    .map((bp) => {
      let transformedHeight: number | undefined;
      if (height && aspectRatio) {
        transformedHeight = Math.round(bp / aspectRatio);
      }

      return {
        url: canonical ? canonical.url : src,
        width: bp,
        height: transformedHeight,
        format,
      };
    });
};

export const DEFAULT_RESOLUTIONS = [
  16, 32, 48, 64, 96, 128, 256, 384, 640, 750, 828, 960, 1080, 1280, 1920, 2048, 2560, 3200, 3840,
  4480, 5120, 6016,
];

export type SrcSetOptions = Omit<ImageSourceOptions, 'src'> & {
  src: URL | string;
  format?: string;
};

export interface ImageSourceOptions {
  src: string;
  width?: number;
  height?: number;
  aspectRatio?: number;
  breakpoints?: number[];
  transformer?: UrlTransformer;
  cdn?: ImageCdn;
}

export const getBreakpoints = ({
  width,
  resolutions = DEFAULT_RESOLUTIONS,
  cdn,
}: {
  width?: number;
  resolutions?: Array<number>;
  cdn?: ImageCdn;
}): number[] => {
  if (!width) return resolutions;

  const doubleWidth = width * 2;

  return [
    // Always include the image at 1x and 2x the specified width
    cdn === 'cloudflare_images' && width,
    cdn === 'cloudflare_images' && doubleWidth,
    // Filter out any resolutions that are larger than the double-res image
    ...resolutions.filter((w) => w < doubleWidth),
  ].filter(Boolean) as number[];
};
